import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-roseville-california.png'
import image0 from "../../images/cities/scale-model-of-johnson-springview-park-in-roseville-california.png"
import image1 from "../../images/cities/scale-model-of-mahany-park-in-roseville-california.png"
import image2 from "../../images/cities/scale-model-of-woodbridge-park-in-roseville-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Roseville'
            state='California'
            image={image}
            lat='38.7521235'
            lon='-121.28800590000003'
            attractions={ [{"name": "Johnson-Springview Park", "vicinity": "5480 5th St, Rocklin", "types": ["park", "point_of_interest", "establishment"], "lat": 38.7900911, "lng": -121.24472550000002}, {"name": "Mahany Park", "vicinity": "1545 Pleasant Grove Blvd, Roseville", "types": ["park", "point_of_interest", "establishment"], "lat": 38.7666992, "lng": -121.33143159999997}, {"name": "Woodbridge Park", "vicinity": "415 Sierra Blvd, Roseville", "types": ["park", "point_of_interest", "establishment"], "lat": 38.7575429, "lng": -121.28053139999997}] }
            attractionImages={ {"Johnson-Springview Park":image0,"Mahany Park":image1,"Woodbridge Park":image2,} }
       />);
  }
}